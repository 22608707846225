<template>
 
  <router-view/>
</template>



<script lang='ts' setup>
import {  onMounted,watch } from 'vue'


import { useRoute } from 'vue-router';
 
const route = useRoute();
 
watch(() => route.fullPath, (newPath, oldPath) => {
  // 路由变化时的处理逻辑
  console.log('路由已变更：', oldPath, '->', newPath);


  // if (window._czc) {
  //         let location = window.location;
  //         let contentUrl = location.pathname + location.hash;
  //         let refererUrl = "/";
  //         window._czc.push(["_trackPageview", contentUrl, refererUrl]);

  //         console.log('已发送页面访问数据');
          
  //       }



});


onMounted(()=>{
  const script = document.createElement("script");
        script.src =
          "https://s9.cnzz.com/z.js?id=1281374417&async=1";   
        script.language = "JavaScript";
        document.body.appendChild(script);
})

</script>



<style lang="scss" scoped>
@import "./assets/css/main.css";

</style>
