import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/standard',
    component: () => import(/* webpackChunkName: "about" */ '@/views/HomeView.vue'),
    children: [
      {
        //CA互认简介
        path: '/introduction',
        name: 'introduction',
        component: () => import(/* webpackChunkName: "about" */ '../views/introduction.vue')
      },
      {
        //新闻
        path: '/news',
        name: 'news',
        component: () => import(/* webpackChunkName: "about" */ '../views/news.vue')
      },
      {
        //互认标准
        path: '/standard',
        name: 'standard',
        component: () => import(/* webpackChunkName: "about" */ '../views/standard.vue')
      },
      {
        //常见问题
        path: '/problem',
        name: 'problem',
        component: () => import(/* webpackChunkName: "about" */ '../views/problem.vue')
      },
      

      {
        //交易系统/交易工具加入
        path: '/systemJoin',
        name: 'systemJoin',
        component: () => import(/* webpackChunkName: "about" */ '../views/systemJoin.vue')
      },
      {
        //App加入
        path: '/appJoin',
        name: 'appJoin',
        component: () => import(/* webpackChunkName: "about" */ '../views/appJoin.vue')
      },

      {
        //机构加入
        path: '/organJoin',
        name: 'organJoin',
        component: () => import(/* webpackChunkName: "about" */ '../views/organJoin.vue')
      },

      {
        //印章加入
        path: '/sealJoin',
        name: 'sealJoin',
        component: () => import(/* webpackChunkName: "about" */ '../views/sealJoin.vue')
      },

      {
        //印章加入
        path: '/sharedComponents',
        name: 'sharedComponents',
        component: () => import(/* webpackChunkName: "about" */ '../views/sharedComponents.vue')
      },

      {
        //共享组件
        path: '/sharedComponents',
        name: 'sharedComponents',
        component: () => import(/* webpackChunkName: "about" */ '../views/sharedComponents.vue')
      },

      {
        //联系我们
        path: '/contactUs',
        name: 'contactUs',
        component: () => import(/* webpackChunkName: "about" */ '../views/contactUs.vue')
      },


      {
        //已开通
        path: '/allActivated',
        name: 'allActivated',
        component: () => import(/* webpackChunkName: "about" */ '../views/allActivated.vue')
      },

      {
        //常见问题
        path: '/askedQuestion',
        name: 'askedQuestion',
        component: () => import(/* webpackChunkName: "about" */ '../views/askedQuestion.vue')
      },



      

      

      
      
      
    ]
  },
  

  
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
