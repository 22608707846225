import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import locale from 'element-plus/es/locale/lang/zh-cn'
import 'highlight.js/styles/atom-one-light.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'


const app = createApp(App)

app.use(ElementPlus,{ locale })
app.use(router)
app.use(hljsVuePlugin)

app.mount('#app')